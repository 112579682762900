import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

// Libraries
import { Trans, useTranslation } from "gatsby-plugin-react-i18next"

// Components
import Section from "components/case-study-section/"
import Carousel from "components/case-study-carousel/"
import Quote from "components/case-study-quote/"

const StyledSection = styled(Section)`
  padding-top: 56px !important;

  ${breakpoint.medium`
    padding-top: 120px!important;
  `}
`

const StyledQuote = styled(Quote)`
  margin-top: 56px;

  ${breakpoint.medium`
    margin-top: 120px;
  `}

  .quote {
    font-size: 18px;
    line-height: 26px;
  }
`

const StyledCarousel = styled(Carousel)`
  margin-top: 40px;

  ${breakpoint.medium`
    margin-top: 80px;
  `}
`

const TheResult = () => {
  const { t } = useTranslation()
  const data = useStaticQuery(graphql`
    query {
      maximiliano: file(
        relativePath: {
          eq: "case-studies/stonehaven/stonehaven-david-frank.png"
        }
      ) {
        childImageSharp {
          fixed(width: 48, height: 48, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }

      carouselImageOne: file(
        relativePath: { eq: "case-studies/stonehaven/the-result-image-1.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 928, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      carouselImageTwo: file(
        relativePath: { eq: "case-studies/stonehaven/the-result-image-2.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 928, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      carouselImageThree: file(
        relativePath: { eq: "case-studies/stonehaven/the-result-image-3.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 928, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      carouselImageFour: file(
        relativePath: { eq: "case-studies/stonehaven/the-result-image-4.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 928, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      carouselImageFive: file(
        relativePath: { eq: "case-studies/stonehaven/the-result-image-5.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 928, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      carouselImageSix: file(
        relativePath: { eq: "case-studies/stonehaven/the-result-image-6.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 928, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <StyledSection className="bg--cream">
      <div className="container">
        <div className="section__title">
          <div className="sticky">
            <p>
              <Trans>The results</Trans>
            </p>
          </div>
        </div>

        <div className="section__content">
          <h3>
            <Trans>
              A new website with refreshed branding that effectively expresses
              Stonehaven’s value proposition and funnels each audience to the
              information they need.
            </Trans>
          </h3>
          <br />
          <p>
            <Trans>
              Stonehaven is constantly innovating its technology and growing its
              community. Now it has a site with innovation and growth written
              all over it. Right from the homepage, users will be able to see a
              value proposition tailored to them and be dazzled by
              sophisticated, innovative design—Two essentials for conversion.
            </Trans>
          </p>
          <br />
          <p>
            <Trans>
              This is just the beginning of Stonehaven’s new chapter. We are
              already working together to apply the new branding to social
              media, sales decks, and other assets that the Stonehaven team will
              be proud to share with their clients.
            </Trans>
          </p>
          <br />
          <p>
            <Trans>
              While we began this project unfamiliar with Stonehaven’s industry,
              we were ultimately able to work together and succeed in this
              project because of the key values we share: Moving forward no
              matter the challenge, and working diligently until we have created
              something we’re proud to show off.
            </Trans>
          </p>
        </div>
      </div>

      <StyledCarousel id="resultsCarousel">
        <div data-slide className="slide align--with-title">
          <Img
            fluid={data.carouselImageOne.childImageSharp.fluid}
            alt="Stonehaven"
          />
        </div>
        <div data-slide className="slide">
          <Img
            fluid={data.carouselImageTwo.childImageSharp.fluid}
            alt="Stonehaven"
          />
        </div>
        <div data-slide className="slide">
          <Img
            fluid={data.carouselImageThree.childImageSharp.fluid}
            alt="Stonehaven"
          />
        </div>
        <div data-slide className="slide">
          <Img
            fluid={data.carouselImageFour.childImageSharp.fluid}
            alt="Stonehaven"
          />
        </div>
        <div data-slide className="slide">
          <Img
            fluid={data.carouselImageFive.childImageSharp.fluid}
            alt="Stonehaven"
          />
        </div>
        <div data-slide className="slide">
          <Img
            fluid={data.carouselImageSix.childImageSharp.fluid}
            alt="Stonehaven"
          />
        </div>
      </StyledCarousel>

      <div className="container">
        <StyledQuote
          className="quote"
          quote={t(
            "“Stonehaven conducted a thorough search for a world-class design and website development team, and we are very happy we found Indicius. The entire process was very professional, thoughtful, creative, and well-managed. We worked extremely closely, and the Indicius team was highly responsive to many rounds of feedback. Tight project management helped us stay on track, and our geographic distance played no role on our seamless communication. The team is very talented and personable, and we look forward to continuing to collaborate on future initiatives.”"
          )}
          author="David Frank"
          position={t("CEO, Founder & Managing Partner, Stonehaven")}
          image={data.maximiliano.childImageSharp.fixed}
          backgroundColor={colors.indiblue}
          color={colors.white}
        />
      </div>
    </StyledSection>
  )
}

export default TheResult
