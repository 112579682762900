import React from "react"
import styled from "styled-components"

// Libraries
import { Trans } from "gatsby-plugin-react-i18next"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

// Utils
import breakpoint from "utils/breakpoints/"

// Components
import Section from "components/case-study-section/"

const StyledSection = styled(Section)`
  padding-top: 56px !important;
  padding-bottom: 0 !important;

  ${breakpoint.medium`
    padding-top: 40px!important;
  `}
`

const ImageWrapper = styled.div`
  max-width: 1440px;
  margin: 16px auto 0 auto;
  overflow: hidden;
  padding-bottom: 85px;

  ${breakpoint.medium`
    margin-top: 60px;
    padding-bottom: 120px;
  `}

  .image {
    width: 95%;
    max-width: 1280px;
    display: block;
    margin-bottom: 16px;

    &:nth-child(even) {
      margin-left: auto;
    }

    ${breakpoint.medium`
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    `}
  }
`

const TheSolution = () => {
  const data = useStaticQuery(graphql`
    query {
      imageOne: file(
        relativePath: { eq: "case-studies/stonehaven/the-solution-image-1.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1280, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      imageTwo: file(
        relativePath: { eq: "case-studies/stonehaven/the-solution-image-2.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1280, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <StyledSection className="bg--cream">
      <div className="container">
        <div className="section__title">
          <div className="sticky">
            <p>
              <Trans>The solution</Trans>
            </p>
          </div>
        </div>

        <div className="section__content">
          <h3>
            <Trans>
              Refining the brand messaging and visual identity to give
              Stonehaven a bold, fresh look and clear representation of the
              value proposition for each audience.
            </Trans>
          </h3>
          <br />
          <p className="title">
            <Trans>1. Brand Narrative Workshop</Trans>
          </p>
          <p>
            <Trans>
              At Indicius we know that a great branding process starts with
              defining the message behind the brand: Its mission, vision,
              values, personality, competitive advantage, and a deep
              understanding of what its customers want and need. That’s why we
              started Stonehaven’s branding process with a Brand Narrative
              Workshop, which took our client through a series of exercises to
              define who Stonehaven is, why it exists, and how they help their
              customers reach their goals.
            </Trans>
          </p>
          <br />
          <p>
            <Trans>
              This workshop formed the basis not only of the written content for
              Stonehaven’s website but also its visual identity. The design team
              paid especially close attention to the “Personality Slider”
              exercise, where we defined Stonehaven as a rebellious, innovative
              player in the market who is trying to shake things up while
              maintaining sophistication and authority.
            </Trans>
          </p>
        </div>
      </div>
      <ImageWrapper>
        <div className="image" data-aos="fade-left" data-aos-duration="600">
          <Img
            fluid={data.imageOne.childImageSharp.fluid}
            alt="Illustration System"
            style={{ width: "100%" }}
          />
        </div>
        <div className="image" data-aos="fade-right" data-aos-duration="600">
          <Img
            fluid={data.imageTwo.childImageSharp.fluid}
            alt="Illustration System"
            style={{ width: "100%" }}
          />
        </div>
      </ImageWrapper>
    </StyledSection>
  )
}

export default TheSolution
