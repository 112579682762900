import React from "react"

// Utils
import { colors } from "utils/variables/"

// Libraries
import { useTranslation } from "gatsby-plugin-react-i18next"

// Components
import CaseStudyHero from "components/case-study-hero/"
import FeaturedImage from "./featured-image"

const Hero = () => {
  const { t } = useTranslation()

  return (
    <React.Fragment>
      <CaseStudyHero
        title={t(
          "Building a bold new look for a trailblazing FinTech platform with multiple audiences"
        )}
        details={[t("Stonehaven"), "New York, USA", "2021"]}
        tags={[
          t("Brand Narrative Workshop"),
          t("Visual Branding"),
          t("Website Design & Development"),
        ]}
        backgroundColor={colors.supernova}
        color={colors.midnight}
        bulletColor={colors.indiblue}
        width={"832px"}
      />
      <FeaturedImage />
    </React.Fragment>
  )
}

export default Hero
