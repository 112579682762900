import React from "react"

// Libraries
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import { Trans } from "gatsby-plugin-react-i18next"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables"

// Components
import Section from "components/case-study-section/"
import breakpoints from "utils/breakpoints/"

const ImageWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  z-index: 9001;
  margin-top: 204px;

  ${breakpoint.small`
    padding: 0 48px;
  `}

  ${breakpoint.medium`
    margin-top: 134px;
  `}

  .image {
    width: 100%;
    display: block;
    margin-bottom: 16px;
    margin-top: -170px;

    &:last-child {
      margin-bottom: 77px;
      margin-top: 0;

      ${breakpoints.medium`
        margin-bottom: 16px;
        margin-top: -41px;
      `}
    }

    ${breakpoint.medium`
      width: calc((100% - 32px) / 2);
      padding-bottom: 90px;
      margin-top: -40px;

      &:last-child {
        margin-bottom: 0;
      }
    `}
  }
`

const StyledBackground = styled.div`
  max-width: 1346px;
  width: 100%;
  background-color: ${colors.bluetiful};
  padding: 0 16px;
  margin: 32px auto 0 auto;
  border-radius: 10px;
  z-index: 9000;
`

const VisualBranding = () => {
  const data = useStaticQuery(graphql`
    query {
      imageOne: file(
        relativePath: {
          eq: "case-studies/stonehaven/visual-branding-image-2.png"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 544, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      imageTwo: file(
        relativePath: {
          eq: "case-studies/stonehaven/visual-branding-image-1.png"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 544, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <Section>
      <div className="container">
        <div className="section__title"></div>
        <div className="section__content">
          <p className="title">
            <Trans>2. Visual Branding</Trans>
          </p>
          <p>
            <Trans>
              The decisions made in the Brand Narrative Workshop were then
              applied to a new logo and Style Tile that would form Stonehaven’s
              new brand identity. The design team presented four options for the
              client to choose.
            </Trans>
          </p>
          <br />
          <p>
            <Trans>
              They started with the new logo, which maintained the idea of
              growth and interconnectedness shown by the arrows in the original
              logo, while freshening it up with modern, geometric shapes. The
              next step was the Style Tile, which forms the basis of a website’s
              look by defining typography, colors, and interface elements.
              Stonehaven’s Style Tile added bright blue (Bluetiful 💙) as the
              main brand color to bring an element of excitement, with pops of
              yellow and burnt orange to give a sense of unexpectedness. As we
              added these exciting new elements, we made sure the brand
              maintained a clean, professional look that would build trust in
              customers.
            </Trans>
          </p>
          <br />
          <p>
            <Trans>
              At the end of this phase, we delivered all the branding elements
              in a Brand Manual, which is a library of the brand’s style that
              serves as a current and future reference for designers. All tied
              up in a bow.
            </Trans>
          </p>
        </div>
      </div>
      <StyledBackground>
        <ImageWrapper>
          <div className="image" data-aos="fade-right" data-aos-duration="600">
            <Img
              fluid={data.imageOne.childImageSharp.fluid}
              alt="Illustration System"
            />
          </div>

          <div className="image" data-aos="fade-left" data-aos-duration="600">
            <Img
              fluid={data.imageTwo.childImageSharp.fluid}
              alt="Illustration System"
            />
          </div>
        </ImageWrapper>
      </StyledBackground>
    </Section>
  )
}

export default VisualBranding
