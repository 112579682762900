import React, { useEffect } from "react"

// Utils
import { graphql } from "gatsby"
import { colors } from "utils/variables/"

// Libraries
import AOS from "aos"
import "aos/dist/aos.css"
import { useTranslation } from "gatsby-plugin-react-i18next"

// Layout
import Layout from "layouts/layout-primary"

// Components
import ContextConsumer from "components/context/"
import SEO from "components/seo/"
import CTA from "components/case-study-call-to-action/"
import { CaseStudyTopic } from "components/case-study-section/"
import NextProject from "components/case-study-next-project"
import NextProjectHero from "sections/case-studies/indicius/hero"

// Sections
import Hero from "sections/case-studies/stonehaven/hero"
import TheChallenge from "sections/case-studies/stonehaven/the-challenge"
import TheSolution from "sections/case-studies/stonehaven/the-solution"
import VisualBranding from "sections/case-studies/stonehaven/visual-branding"
import WebsiteDesign from "sections/case-studies/stonehaven/website-design"
import TheProject from "sections/case-studies/stonehaven/the-project"
import TheResult from "sections/case-studies/stonehaven/the-result"

const Stonehaven = props => {
  useEffect(() => {
    AOS.init({
      once: true,
    })
  }, [])

  const {
    openGraphImage: { file: publicURL },
  } = props.data

  const { t } = useTranslation()

  return (
    <Layout menuColor={colors.supernova}>
      <ContextConsumer>
        {({ data, set }) => {
          if (data.useYellowFooter) {
            set({ useYellowFooter: false })
          }
          if (!data.darkMenu) {
            set({ darkMenu: true })
          }
          if (data.showFooter) {
            set({ showFooter: false })
          }
          if (data.simplifiedFooter) {
            set({ simplifiedFooter: false })
          }
        }}
      </ContextConsumer>
      <SEO
        title={t("Stonehaven Case Study - Indicius Design Agency")}
        description={t(
          "We design websites, apps, and brand identities for amazing clients. Find out how we redesigned Columbia University's genomics app for on-the-go analysis."
        )}
        image={publicURL}
      />
      <Hero />
      <CaseStudyTopic>
        <TheProject />
      </CaseStudyTopic>
      <CaseStudyTopic>
        <TheChallenge />
        <TheSolution />
      </CaseStudyTopic>

      <CaseStudyTopic>
        <VisualBranding />
        <WebsiteDesign />
      </CaseStudyTopic>
      <CaseStudyTopic>
        <TheResult />
      </CaseStudyTopic>
      <CTA
        title={t(
          "Need to optimize an existing product design for usability and scalability?"
        )}
      />
      <NextProject url="/work/indicius" nextProjectHero={<NextProjectHero />} />
    </Layout>
  )
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    openGraphImage: file(
      relativePath: { eq: "case-studies/stonehaven/featured-image.png" }
    ) {
      publicURL
    }
  }
`

export default Stonehaven
