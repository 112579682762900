import React from "react"

// Libraries
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import { Trans } from "gatsby-plugin-react-i18next"

// Utils
import breakpoint from "utils/breakpoints/"

// Components
import Section from "components/case-study-section/"

const ImageWrapper = styled.div`
  max-width: 1120px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0 16px;
  margin: 32px auto 0 auto;
  overflow: hidden;

  ${breakpoint.small`
    padding: 0 48px;
  `}

  ${breakpoint.medium`
    margin-top: 88px;
  `}

  .image {
    width: 100%;
    display: block;
    margin-bottom: 16px;

    ${breakpoint.medium`
      width: 70%;
      margin: 0;

      &:last-child {
        margin-bottom: 0;
        width: 28%;
      }
    `}
  }
`

const VisualBranding = () => {
  const data = useStaticQuery(graphql`
    query {
      imageOne: file(
        relativePath: {
          eq: "case-studies/stonehaven/website-design-image-1.png"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 736, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      imageTwo: file(
        relativePath: {
          eq: "case-studies/stonehaven/website-design-image-2.png"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 352, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      imageThree: file(
        relativePath: {
          eq: "case-studies/stonehaven/website-design-image-3.png"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 352, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <Section>
      <div className="container">
        <div className="section__title"></div>
        <div className="section__content">
          <p className="title">
            <Trans>3. Website Design</Trans>
          </p>
          <p>
            <Trans>
              With the style tile confirmed, we moved onto the longest, yet most
              rewarding phase of the process: Design and development of the
              marketing website.
            </Trans>
          </p>
          <br />
          <p>
            <Trans>
              Building on our clients’ ideas, a few key elements of the new site
              helped us speak to all three of Stonehaven’s audiences—Affiliate
              Partners, companies, and investors—Right off the Home page. One
              was an animated sentence in the Hero that rotated through the
              services for each audience. Another was the value proposition
              chart, whose content we worked and re-worked until it perfectly
              represented the many areas of value for each audience, based on
              which one a user clicks.
            </Trans>
          </p>
          <br />
          <p>
            <Trans>
              Perhaps the crowning achievement of the web redesign was a custom
              set of animated, isometric illustrations and icons. The
              illustrators at Esmile Studio worked closely with us and our
              client to ensure each illustration clearly and accurately depicted
              each of Stonehaven’s values and services. Illustration animations
              were accompanied by on-scroll illustrations, on the homepage
              particularly, giving the whole site a sense of constant movement
              and flow.
            </Trans>
          </p>
        </div>
      </div>

      <ImageWrapper>
        <div className="image" data-aos="fade-right" data-aos-duration="600">
          <Img
            fluid={data.imageOne.childImageSharp.fluid}
            alt="Illustration System"
          />
        </div>

        <div className="image" data-aos="fade-left" data-aos-duration="600">
          <Img
            fluid={data.imageThree.childImageSharp.fluid}
            alt="Illustration System"
          />
          <Img
            fluid={data.imageTwo.childImageSharp.fluid}
            alt="Illustration System"
          />
        </div>
      </ImageWrapper>
    </Section>
  )
}

export default VisualBranding
