import React from "react"

// Components
import Section from "components/case-study-section/"
import styled from "styled-components"

// Libraries
import { Trans } from "gatsby-plugin-react-i18next"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables"

// Post its

import PostItOne from "assets/icons/case-studies/stonehaven/design-sprint-icon-1.inline.svg"
import PostItTwo from "assets/icons/case-studies/stonehaven/design-sprint-icon-2.inline.svg"
import PostItThree from "assets/icons/case-studies/stonehaven/design-sprint-icon-3.inline.svg"
import PostItFour from "assets/icons/case-studies/stonehaven/design-sprint-icon-4.inline.svg"

const PostItWrapper = styled.div`
  flex-shrink: 0;
  display: flex;
  justify-content: space-between;
  overflow-x: auto;
  margin-top: 52px;

  ${breakpoint.small`
    width: 100%;
  `}

  ${breakpoint.medium`
    width: auto;
    justify-content: center;
    margin-top: 80px;
  `}

  svg {
    flex-shrink: 0;
    margin-right: 8px;

    &:last-child {
      margin-right: 0;
    }

    ${breakpoint.small`
      flex-shrink: 1;
    `}

    ${breakpoint.medium`
      width: 224px;
      height: auto;
    `}
  }
`

const TheChallenge = () => (
  <Section className="bg--cream">
    <div className="container">
      <div className="section__title">
        <div className="sticky">
          <p>
            <Trans>The challenge</Trans>
          </p>
        </div>
      </div>

      <div className="section__content">
        <h3>
          <Trans>
            How can we build a website that markets to all three of Stonehaven’s
            audiences while effectively communicating the value they provide to
            each?
          </Trans>
        </h3>
        <br />
        <p>
          <Trans>
            Founded by David Frank in 2001, Stonehaven is a global capital
            markets FinTech platform that allows Affiliate Partners (investment
            bankers and placement agents) and companies to raise capital,
            conduct secondaries, and execute M&A transactions while helping
            investors source, diligence and invest.{" "}
          </Trans>
        </p>
        <br />
        <p>
          <Trans>
            If you’re not familiar with the broker-dealer industry, that may
            sound complicated—It did to us, too, when we started this project.
            So to break it down, Stonehaven exists for this reason: Companies
            want to grow and conduct other transactions, investors want to
            identify high quality investment opportunities, and Affiliate
            Partners want to strategically connect the two. That’s why
            Stonehaven created its platform—To provide the infrastructure,
            technology and community facilitate capital markets activities
            between these 3 major players.
          </Trans>
        </p>
        <br />
        <p>
          <Trans>
            When Stonehaven reached out to us to help overhaul its brand and
            marketing website, our main challenge became clear: We needed to
            create a site that spoke to all three audiences simultaneously,
            while funneling them to learn more about the value Stonehaven
            provides to them specifically. The current site was classic and
            informative, but lacked boldness and modernity that would tell the
            world they were the most innovative, intelligent platform out there.
          </Trans>
        </p>
        <br />
        <p>
          <Trans>
            We wanted to make sure it was clear right from the Homepage that
            Stonehaven is on the cutting edge of FinTech. In collaboration with
            our partners at NK Studio, this is how we made it happen.
          </Trans>
        </p>
      </div>
      <div className="post-it-wrapper">
        <PostItWrapper>
          <PostItOne />
          <PostItTwo />
          <PostItThree />
          <PostItFour />
        </PostItWrapper>
      </div>
    </div>
  </Section>
)

export default TheChallenge
