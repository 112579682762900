import React from "react"

// Libraries
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import Img from "gatsby-image"
import { Trans } from "gatsby-plugin-react-i18next"

import breakpoint from "utils/breakpoints/"

// Components
import Section from "components/case-study-section/"

const StyledSection = styled(Section)`
  overflow-y: hidden;

  .image-wrapper {
    margin-top: 20px;
    margin-bottom: 20px;

    ${breakpoint.medium`
        margin-top: 0;
        margin-bottom: 0;
    `}

    &.image--one {
      left: -16px;
      margin-bottom: 16px;

      ${breakpoint.medium`
        left: 160px;
      `}

      ${breakpoint.extraLarge`
        left: calc(100vw - 1380px);
        right: calc(100vw - 1280px);
      `}
    }

    &.image--two {
      object-position: center right;
      left: -16px;

      ${breakpoint.extraLarge`
        right: 0;
        left: calc(100vw - 1280px);
      `}
    }
  }
`

const TheProject = () => {
  const data = useStaticQuery(graphql`
    query {
      imageOne: file(
        relativePath: { eq: "case-studies/stonehaven/the-project-image-1.png" }
      ) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      imageTwo: file(
        relativePath: { eq: "case-studies/stonehaven/the-project-image-2.png" }
      ) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <StyledSection>
      <div className="container">
        <div className="section__title">
          <div className="sticky">
            <p>
              <Trans>The project</Trans>
            </p>
          </div>
        </div>

        <div className="section__content">
          <p>
            <Trans>
              Stonehaven’s platform brings together the major players in the
              capital markets industry to connect on one cutting-edge Fintech
              platform. It’s a high-tech, future-focused company, but its
              website, with its greyscale and reserved graphics, wasn’t
              expressing that. Stonehaven went through a brand overhaul and a
              website makeover to show that they’re not your typical
              broker-dealer–They’re on a whole other level.
            </Trans>
          </p>
        </div>
      </div>

      <div className="image-wrapper">
        <div data-aos="indicius-slide-left">
          <Img
            className="img image--one"
            fluid={data.imageOne.childImageSharp.fluid}
            alt="Stonehaven"
          />
        </div>
        <div data-aos="indicius-slide-right">
          <Img
            className="img image--two"
            fluid={data.imageTwo.childImageSharp.fluid}
            alt="Stonehaven"
          />
        </div>
      </div>
    </StyledSection>
  )
}

export default TheProject
